<template>
  <ModalMenu class-name="web_push_modal" :is-teleport-enabled="true" :background-scroll="false">
    <template #title>
      <a href="#" class="web_push_modal-close" data-test-id="btn_close" @click.prevent="close">
        <i class="icon icon-close"></i>
      </a>

      <div class="mx-auto mb-32 text-center">
        <img src="../../assets/img/easy-start-pack/credits-2.png" width="88" height="88" alt="" />
      </div>

      <span class="text-warning">{{ credits.value ?? 0 }} {{ t('credits') }}</span>
      <br />
      {{ t('header') }}
    </template>
    <template #text>
      <div
        v-if="!splitWhitelistCleanup.isOrganicV2"
        class="web_push_modal-content text-body2 font-normal"
      >
        {{ t('sendSomeone') }}
      </div>

      <button type="button" class="btn btn-primary btn-block" @click="close">
        {{ t('gotIt') }}
      </button>
    </template>
  </ModalMenu>
</template>
<script lang="ts">
// these params are necessary for modal service
export default { isPaymentModal: true };
</script>
<script lang="ts" setup>
import { PropType, Ref } from 'vue';

import ModalMenu from '@/components/modal/modal-menu/ModalMenu.vue';
import { useSplitWhitelistCleanup } from '@/services/growth-book/hooks/use-split-whitelist-cleanup/use-split-whitelist-cleanup';
import { useI18n } from '@/services/i18n';

import { messages } from './i18n';

const { t } = useI18n({ messages });
const emit = defineEmits(['close']);
defineProps({
  credits: {
    type: Object as PropType<Ref<number>>,
    required: true,
  },
});

const splitWhitelistCleanup = useSplitWhitelistCleanup();

function close(): void {
  emit('close');
}

splitWhitelistCleanup.init();
</script>
