export enum PossibilityPackage {
  ASTROLOGY_CHECK = 'pos-astrology-one',
  SEXUAL_CHECK = 'pos-sexual-one',
  POWER_LIKE = 'pos-powerlike-one',
  POWER_LIKE_DONATION = 'pos-powerdonation-one',
  TOP_PROFILE_COMMON = 'pos-top-profile-common-one',
  TOP_PROFILE_PLUS = 'pos-top-profile-plus-one',
}

export enum PossibilityPackageWhitelistCleanup {
  POWER_LIKE = 'pos-powerlike-one',
  POWER_LIKE_DONATION = 'pos-powerdonation-one',
  TOP_PROFILE_COMMON = 'pos-top-profile-common-one',
  TOP_PROFILE_PLUS = 'pos-top-profile-plus-one',
}
