<template>
  <div
    :class="{
      'footer_chat--big_message': openedMode === 'message',
      'footer_chat--open_panel': openedMode === 'gifts',
      'footer_icebreaker--open_panel': openedMode === 'icebreaker',
    }"
    class="footer footer_chat"
    data-test-id="chat_footer"
    @click.stop
  >
    <div class="flex footer_chat-wrap">
      <div class="footer_chat-input grow">
        <template v-if="!splitWhitelistCleanup.isOrganicV2">
          <button
            v-if="openedMode === 'gifts'"
            type="button"
            class="chat-input-gift chat-input-gift--close"
            data-test-id="btn_close"
            @click="openMode('')"
          >
            <FontIcon class="icon-close" />
          </button>

          <button
            v-else
            type="button"
            class="chat-input-gift"
            data-test-id="btn_gift_chat"
            @click="openGifts"
          >
            <FontIcon class="icon-gift" />
            <span class="img-badge badge-red"></span>

            <FadeTransition>
              <div
                v-if="hint === 'gift' && isHintVisible"
                class="tooltip tooltip--top left-0 red show"
              >
                <div class="tooltip-body">
                  <div class="tooltip-inner">{{ t('hintGift') }}</div>
                </div>
                <div class="arrow"></div>
              </div>
            </FadeTransition>
          </button>
        </template>

        <template v-if="isIceBreakerButtonVisible">
          <button
            v-if="openedMode === 'icebreaker'"
            type="button"
            class="chat-btn-icebreacker chat-btn-icebreacker--close"
            data-test-id="btn_close"
            @click="openMode('')"
          >
            <FontIcon class="icon-close" />
          </button>

          <button
            v-else
            type="button"
            class="chat-btn-icebreacker"
            data-test-id="btn_icebreakers_chat"
            @click="openIceBreakers"
          >
            <FontIcon class="icon-icebreacker" />
            <span class="img-badge badge-green"></span>
            <FadeTransition>
              <div
                v-if="hint === 'ice-breaker' && isHintVisible"
                class="tooltip tooltip--top right-0 green show"
              >
                <div class="tooltip-body">
                  <div class="tooltip-inner">{{ t('hintIce') }}</div>
                </div>
                <div class="arrow"></div>
              </div>
            </FadeTransition>
          </button>
        </template>

        <!--
         Attribute below is required because of this issue:
         https://github.com/vuejs/vuejs.org/pull/1798
         v-on:input="messageText = $event.target.value"
         -->
        <textarea
          v-model="messageText"
          data-id="chat-message-form"
          data-test-id="text_field_chat"
          :maxlength="maxMessageLength"
          class="message-input message-input-chat"
          :class="{ 'whitelist-cleanup': splitWhitelistCleanup.isOrganicV2 }"
          :placeholder="t('type')"
          @input="setMessageTextFromEvent($event)"
          @focus="openMode('message')"
          @keydown.ctrl.enter="sendTextMessage"
          @keydown.meta.enter="sendTextMessage"
        ></textarea>
      </div>

      <div class="footer_chat-camera">
        <button
          v-show="!isSendButtonDisabled"
          type="button"
          class="message-btn"
          data-test-id="btn_send_message"
          @click="sendTextMessage"
        >
          <SvgIcon name="send_active" :width="32" :height="32" />
        </button>
        <button
          v-show="isSendButtonDisabled"
          type="button"
          class="footer_chat-camera_btn"
          data-test-id="btn_send_photo_chat"
          @click="selectPhoto"
        >
          <FontIcon class="icon-photo" />
        </button>
      </div>
    </div>

    <ChatFooterIcebreaker
      v-if="openedMode === 'icebreaker'"
      :ice-breakers="iceBreakers"
      @select="applyIceBreaker"
    />

    <ChatFooterGifts
      v-if="openedMode === 'gifts'"
      :gifts="gifts"
      :are-free-gifts-available="areFreeGiftsAvailable"
      @send="sendGift"
    />
  </div>
</template>

<script lang="ts" setup>
import { DialogGift } from '@project-gd-x/dating-site-contracts/src/gen/gdx/gateway/web/chat/v1/dialogs_pb';
import { FeatureType } from '@project-gd-x/dating-site-contracts/src/gen/gdx/market/v1/package_pb';
import { computed, onUnmounted, PropType, ref, watch } from 'vue';

import FontIcon from '@/components/inputs/icon/font-icon/FontIcon.vue';
import SvgIcon from '@/components/inputs/icon/svg-icon/SvgIcon.vue';
import { isAllowedCreditsAction } from '@/components/payment/helpers/payment/payment';
import FadeTransition from '@/components/transition/FadeTransition.vue';
import { hasPremium } from '@/helpers/feature/feature';
import { customEventTrigger } from '@/helpers/gtm-triggers/gtm-triggers';
import { useModel } from '@/hooks/use-model/use-model';
import ChatFooterGifts from '@/pages/chat/components/chat-view/components/chat-footer/components/chat-footer-gifts/ChatFooterGifts.vue';
import ChatFooterIcebreaker from '@/pages/chat/components/chat-view/components/chat-footer/components/chat-footer-icebreaker/ChatFooterIcebreaker.vue';
import { isChatPromoGiftVisible } from '@/pages/chat/components/chat-view/components/chat-footer/helpers/gift-promo-visibility/gift-promo-visibility';
import {
  ChatStateDialogMessage,
  ChatStateDialogMessageContent,
} from '@/pages/chat/store/chat-state';
import { resolveCreditsLastAction } from '@/pages/payment/helpers/credits-last-action/credits-last-action';
import { useSplitWhitelistCleanup } from '@/services/growth-book/hooks/use-split-whitelist-cleanup/use-split-whitelist-cleanup';
import { useI18n } from '@/services/i18n';
import { selectImage, uploadImage } from '@/services/image';
import { commit, dispatch, getState } from '@/store/store-helper';
import { ExtractClassFields } from '@/type';

import * as i18n from './i18n';

type OpenedMode = 'gifts' | 'message' | 'icebreaker' | '';
export type ChatFooterHint = '' | 'gift' | 'ice-breaker';

const PREMIUM_GOLD_MESSAGE_LENGTH = 2000;
const PAID_USER_MAX_MESSAGE_LENGTH = 1000;
const FREE_USER_MAX_MESSAGE_LENGTH = 200;

const props = defineProps({
  gifts: {
    type: Array as PropType<ExtractClassFields<DialogGift>[]>,
    required: true,
  },
  isPowerExpired: {
    type: Boolean,
    default: false,
  },
  userId: {
    type: String,
    default: '',
  },
  iceBreakers: {
    type: Array as PropType<string[]>,
    required: true,
  },
  dialogId: {
    type: String,
    required: true,
  },
  isHintVisible: {
    type: Boolean,
    default: true,
  },
  dialogMessages: {
    type: Array as PropType<ChatStateDialogMessage[]>,
    default: () => [],
  },
  areMessagesLoaded: {
    type: Boolean,
    default: false,
  },
  tooltipHint: {
    type: String,
    default: '',
  },
});

const emit = defineEmits([
  'send-message',
  'close',
  'open',
  'showChatPromoGift',
  'update:tooltipHint',
]);

const splitWhitelistCleanup = useSplitWhitelistCleanup();

const hint = useModel<string>(props, 'tooltipHint', emit);

const { t } = useI18n({ messages: i18n.messages });

const openedMode = ref<OpenedMode>('');
const isMessageSending = ref(false);
const messageText = ref('');

const isIceBreakerButtonVisible = computed(() => {
  return !props.dialogMessages.length;
});

const isSendButtonDisabled = computed(() => {
  return (
    messageText.value.length === 0 ||
    messageText.value.length > maxMessageLength.value ||
    isMessageSending.value
  );
});

const freeGiftsCount = computed(() => {
  return getState().payment.freeGiftsCount;
});

const areFreeGiftsAvailable = computed(() => {
  return freeGiftsCount.value > 0;
});

const activeDraft = computed(() => {
  return getState().chat.activeDrafts[props.dialogId] ?? '';
});

const maxMessageLength = computed(() => {
  if (getState().common.index.isPremiumGoldActive) {
    return PREMIUM_GOLD_MESSAGE_LENGTH;
  }

  return !hasPremium() ? FREE_USER_MAX_MESSAGE_LENGTH : PAID_USER_MAX_MESSAGE_LENGTH;
});

// const messages = computed<ChatStateDialogMessage[]>(() => {
//   return getState().chat.messages[props.dialogId] || [];
// });

function setMessageTextFromEvent(event: Event) {
  messageText.value = (event.target as HTMLTextAreaElement).value;
}

async function selectPhoto() {
  try {
    const blob = await selectImage();
    const { imageId } = await uploadImage(blob, 'chat');

    return sendImageMessage(imageId);
  } catch (e) {
    // eslint-disable-next-line no-alert
    alert(e);
  }
}

function sendGift(giftId: string) {
  openMode('');
  sendGiftMessage(giftId, props.userId);
}

function applyIceBreaker(ice: string) {
  messageText.value = ice;

  sendTextMessage();
  openMode('message');
}

async function sendGiftMessage(giftId: string, userId: string) {
  resolveCreditsLastAction('gift', userId);

  const selected = props.gifts.find((item: ExtractClassFields<DialogGift>) => item.id === giftId);

  if (selected) {
    const { priceCredits } = selected;

    const send = async () => {
      await sendMessage({
        type: 'gift',
        giftId,
        giftPhotoId: selected.photoId,
        giftText: '',
      });

      commit('mutationPaymentSetFreeGiftsCount', freeGiftsCount.value - 1);
      customEventTrigger({
        event: 'chat-send-gift-message',
      });
    };

    if (selected.isFree) {
      if (hasPremium()) {
        return send();
      } else if (!selected.availableInDuration) {
        // 24 hours in seconds
        const seconds = 86_400n;

        selected.availableInDuration = {
          seconds,
          nanos: 0,
        };

        return send();
      }
    }

    if (await isAllowedCreditsAction(priceCredits, FeatureType.INVALID, 'chat')) {
      send();
    } else {
      customEventTrigger({
        event: 'show-credits-page',
        block: 'chat-gift',
      });
    }
  }
}

async function sendImageMessage(imageId: string) {
  await sendMessage({
    type: 'image',
    imageId,
  });
  customEventTrigger({
    event: 'chat-send-image-message',
  });
  openMode('');
  triggerChatGiftPromoVisibility();
}

async function sendTextMessage() {
  commit('mutationChatAddDraftsMessage', {
    dialogId: props.dialogId,
    message: messageText.value,
  });

  await sendMessage({
    type: 'text',
    text: messageText.value,
  });

  messageText.value = '';

  customEventTrigger({
    event: 'chat-send-text-message',
  });
  openMode('');
  triggerChatGiftPromoVisibility();
}

function triggerChatGiftPromoVisibility(): void {
  if (isChatPromoGiftVisible(props.dialogId)) {
    emit('showChatPromoGift');
  }
}

async function sendMessage(content: ChatStateDialogMessageContent) {
  isMessageSending.value = true;
  emit('send-message');
  await dispatch('actionChatSendMessage', {
    chatId: props.dialogId,
    content,
  })
    .then(() => {
      commit('mutationChatDecrementFreeMessagesCount', props.dialogId);
    })
    .finally(() => {
      isMessageSending.value = false;
    });
}

function openIceBreakers(): void {
  openMode('icebreaker');
}

function openGifts(): void {
  openMode('gifts');
}

function openMode(mode: OpenedMode) {
  if (openedMode.value !== mode) {
    openedMode.value = mode;

    if (mode === '') {
      emit('close');
    } else {
      emit('open');
    }
  }

  if (openedMode.value !== '') {
    hint.value = '';
  }
}

function clickOutside() {
  openMode('');
}

function setMessageFromDrafts(): void {
  messageText.value = activeDraft.value;
}

watch(
  () => props.areMessagesLoaded,
  (value: boolean) => {
    if (!props.dialogMessages.length && value) {
      openedMode.value = 'icebreaker';
    }
  },
  { immediate: true },
);

watch(
  () => props.isPowerExpired,
  (value: boolean) => {
    if (value) {
      openedMode.value = '';
    }
  },
  { immediate: true },
);

watch(activeDraft, () => {
  setMessageFromDrafts();
});

onUnmounted(() => {
  window.removeEventListener('click', clickOutside);
});

defineExpose({
  sendGiftMessage,
});

window.addEventListener('click', clickOutside);
setMessageFromDrafts();
dispatch('actionCommonGetFreeCreditsFeaturesStatus');

splitWhitelistCleanup.init();
</script>

<style scoped lang="scss" src="./style.scss"></style>
