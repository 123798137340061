import { sha256 } from 'js-sha256';

import { getUserId } from '@/helpers/auth/auth';
import { dispatch } from '@/store/store-helper';

declare global {
  interface Window {
    TiktokAnalyticsObject?: string;
    ttq?: any;
  }
}

export type TiktokEventName = 'AddPaymentInfo' | 'InitiateCheckout' | string;

export function trackTiktokPixel(
  eventName: TiktokEventName,
  params?: Record<string, unknown>,
  eventId?: string,
) {
  const paramList = [eventName, params];

  if (eventId) {
    paramList.push({
      event_id: eventId,
    });
  }

  if (typeof window.ttq !== 'undefined') {
    window.ttq.track(...paramList);
  } else {
    console.log('TT pixel is disabled on dev', ...paramList);
  }
}

export function loadTiktokScript() {
  if (+import.meta.env.VUE_APP_IS_FACEBOOK_PIXEL_ENABLED) {
    // @ts-ignore
    (function (w, _d, t) {
      w.TiktokAnalyticsObject = t;
      // @ts-ignore
      const ttq = (w[t] = w[t] || []);

      // eslint-disable-next-line no-unused-expressions
      (ttq.methods = [
        'page',
        'track',
        'identify',
        'instances',
        'debug',
        'on',
        'off',
        'once',
        'ready',
        'alias',
        'group',
        'enableCookie',
        'disableCookie',
        'holdConsent',
        'revokeConsent',
        'grantConsent',
        // eslint-disable-next-line no-sequences
      ]),
        // @ts-ignore
        (ttq.setAndDefer = function (t, e) {
          t[e] = function () {
            // eslint-disable-next-line prefer-rest-params
            t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
          };
        });
      for (let i = 0; i < ttq.methods.length; i++) ttq.setAndDefer(ttq, ttq.methods[i]);
      // @ts-ignore

      // @ts-ignore
      // eslint-disable-next-line no-unused-expressions
      (ttq.instance = function (t) {
        // eslint-disable-next-line no-var
        for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++)
          ttq.setAndDefer(e, ttq.methods[n]);
        return e;
        // eslint-disable-next-line no-sequences
      }),
        // @ts-ignore
        (ttq.load = function (e, n) {
          const r = 'https://analytics.tiktok.com/i18n/pixel/events.js';
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const o = n && n.partner;
          // eslint-disable-next-line no-unused-expressions,no-sequences
          (ttq._i = ttq._i || {}),
            (ttq._i[e] = []),
            (ttq._i[e]._u = r),
            (ttq._t = ttq._t || {}),
            (ttq._t[e] = +new Date()),
            (ttq._o = ttq._o || {}),
            (ttq._o[e] = n || {});
          n = document.createElement('script');
          // eslint-disable-next-line no-sequences,no-unused-expressions
          (n.type = 'text/javascript'), (n.async = !0), (n.src = r + '?sdkid=' + e + '&lib=' + t);
          e = document.getElementsByTagName('script')[0];
          e.parentNode.insertBefore(n, e);
        });
      ttq.load('CUKRQJ3C77U27ULH6IQ0');
      ttq.page();

      dispatch('actionSettingsGetAccount').then((account) => {
        ttq.identify({
          email: sha256(account.email),
          external_id: sha256(getUserId()),
        });
      });
    })(window, document, 'ttq');
  }
}
