<template>
  <div :class="'swiper-gallery-container' + id" class="slider slider_billing">
    <ul
      class="slider-indicators"
      :class="'swiper-gallery-pagination' + id"
      data-test-id="slider_indicators"
    ></ul>
    <div class="slider-inner swiper-wrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Swiper, { Autoplay, Pagination } from 'swiper';
import { nextTick, onMounted } from 'vue';

import { getUniqNumber } from '@/helpers/number';
// Using require because of this issue https://github.com/nolimits4web/swiper/issues/3708
import 'swiper/swiper-bundle.css';
import { useSplitWhitelistCleanup } from '@/services/growth-book/hooks/use-split-whitelist-cleanup/use-split-whitelist-cleanup';

Swiper.use([Pagination, Autoplay]);

const props = defineProps({
  initialActive: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(['slide-change']);

const id = getUniqNumber();

onMounted(() => {
  nextTick(() => {
    useSplitWhitelistCleanup()
      .init()
      .then(
        () =>
          new Swiper(`.swiper-gallery-container${id}`, {
            loop: false,
            initialSlide: props.initialActive,
            on: {
              slideChange: (swiper) => {
                emit('slide-change', swiper.realIndex);
              },
            },
            pagination: {
              el: `.swiper-gallery-pagination${id}`,
              type: 'bullets',
              clickable: true,
              bulletClass: 'bullet',
              bulletActiveClass: 'active',
              renderBullet(index, className) {
                return `<li class="${className}"></li>`;
              },
            },
            autoplay: {
              delay: 5000,
              disableOnInteraction: false,
            },
          }),
      );
  });
});
</script>

<style scoped lang="scss" src="./style.scss"></style>
